import { createRouter, createWebHistory } from 'vue-router';
//import { createWebHistory, createRouter } from "vue-router";


// [라우터 path 접속 경로 설정]
const routes = [
  {
    path: "/",
    name: "index",
    //component: () => import('@/components/HelloWorld')
    component: () => import('@/pages/main')
  },
  {
    path: "/old",
    name: "index_old",
    //component: () => import('@/components/HelloWorld')
    component: () => import('@/pages/main_old')
  },
  {
    path: "/main",
    name: "main",
    component: () => import('@/components/MainComponent')
  },
  {
    path: "/join",
    name: "join",
    component: () => import('@/components/JoinComponent')
  },
  {
    path: "/login",
    name: "login",
    component: () => import('@/pages/login'),
    // beforeEnter: beforeAuth(false),
  },
  {   //플래닝
    path: "/planning",
    name: "planning",
    component: () => import('@/pages/planning'),
    // beforeEnter: beforeAuth(false),
  },
  {  //플래닝view
    path: "/planning_view",
    name: "planning_view",
    component: () => import('@/pages/planning_view'),
    // beforeEnter: beforeAuth(false),
  },
  {
    path: "/image/list",
    name: "image_list",
    component: () => import('@/pages/image_list'),
    // beforeEnter: beforeAuth(false),
  },
  {
    path: "/image/detail/:no",
    name: "image_view",
    component: () => import('@/pages/image_view'),
    // beforeEnter: beforeAuth(false),
  },
  // 쇼핑 라우터
  {
    path: "/shop/list",
    name: "shop_list",
    component: () => import('@/pages/shop_list'),
    // beforeEnter: beforeAuth(false),
  },
  {
    path: "/shop/:it_id",
    name: "shop_view",
    component: () => import('@/pages/shop_view'),
    // beforeEnter: beforeAuth(false),
  },
  {   // 기획방
    path: "/room/list",
    name: "room_list",
    component: () => import('@/pages/room_list'),
    // beforeEnter: beforeAuth(false),
  },
  {   // 기획방 코멘트 모달
    path: "/room/comment_w",
    name: "room_comment_w",
    component: () => import('@/pages/comment_w'),
    // beforeEnter: beforeAuth(false),
  },
  {   // 기획방뷰
    path: "/room/view/:idx",
    name: "room_view",
    component: () => import('@/pages/room_view'),
    // beforeEnter: beforeAuth(false),
  },
  {   // 기획방등록
    path: "/room/write",
    name: "room_write",
    component: () => import('@/pages/room_write'),
    // beforeEnter: beforeAuth(false),
  },
  {   // 기획방 수정
    path: "/room/write/:idx",
    name: "room_edit",
    component: () => import('@/pages/room_write'),
    // beforeEnter: beforeAuth(false),
  },
  {   // 기획방 참여방법
    path: "/room/info",
    name: "room_info",
    component: () => import('@/pages/room_info'),
    // beforeEnter: beforeAuth(false),
  },
  {   // 기획방리뷰
    path: "/room/review",
    name: "room_review",
    component: () => import('@/pages/room_review'),
    // beforeEnter: beforeAuth(false),
  },
  {   // 기획방 세시에
    path: "/room/3pm",
    name: "room_3pm",
    component: () => import('@/pages/room_3pm'),
    // beforeEnter: beforeAuth(false),
  },
  {   // 장바구니
    path: "/cart",
    name: "cart",
    component: () => import('@/pages/cart'),
    // beforeEnter: beforeAuth(false),
  },
  {   // 주문서 작성
    path: "/order",
    name: "order",
    component: () => import('@/pages/order'),
    // beforeEnter: beforeAuth(false),
  },
  {   // 주문완료
    path: "/order_complete/:od_id",
    name: "order_complete",
    component: () => import('@/pages/order_complete'),
    // beforeEnter: beforeAuth(false),
  },
  {   // 주문완료
    path: "/order/detail/:od_id",
    name: "order_detail",
    component: () => import('@/pages/order_detail'),
    // beforeEnter: beforeAuth(false),
  },
  {   // 마이페이지-주문내역
    path: "/mypage/order_list",
    name: "my_order_list",
    component: () => import('@/pages/my_order_list'),
    // beforeEnter: beforeAuth(false),
  },
  {   // 마이페이지-주문내역상세
    path: "/mypage/order_view",
    name: "my_order_view",
    component: () => import('@/pages/my_order_view'),
    // beforeEnter: beforeAuth(false),
  },



  // 관리자 -------------------------------------------------------------------------------
  {
    path: "/adm/member_list",
    name: "member_list",
    component: () => import('@/pages/admin/memberList')
    //, beforeEnter: beforeAuth(true),
  },
  {
    path: "/adm/author_list",
    name: "author_list",
    component: () => import('@/pages/admin/memberList')
    //, beforeEnter: beforeAuth(true),
  },

  {
    path: "/adm/member_list/author",
    name: "member_author",
    component: () => import('@/pages/admin/memberListAuthor')
    //, beforeEnter: beforeAuth(true),
  },
  {
    path: "/adm/member_list/author_wait",
    name: "member_author_wait",
    component: () => import('@/pages/admin/memberList')
    //, beforeEnter: beforeAuth(true),
  },
  {
    path: "/adm/author_view/:uid",
    name: "author_view",
    component: () => import('@/pages/admin/memberViewAuthor')
  },
  {
    path: "/adm/author_new/",
    name: "author_new",
    component: () => import('@/pages/admin/memberViewAuthor')
  },
  {
    path: "/adm/member_list/normal",
    name: "member_normal",
    component: () => import('@/pages/admin/memberList')
    //, beforeEnter: beforeAuth(true),
  },
  {
    path: "/adm/member_list/design",
    name: "member_design",
    component: () => import('@/pages/admin/memberList')
    //, beforeEnter: beforeAuth(true),
  },
  {
    path: "/adm/member_list/plan",
    name: "member_plan",
    component: () => import('@/pages/admin/memberList')
    //, beforeEnter: beforeAuth(true),
  },
  {
    path: "/adm/member_view/:idx",
    name: "member_view",
    component: () => import('@/pages/admin/memberView')
  },
  {
    path: "/adm/member_view",
    name: "member_new",
    component: () => import('@/pages/admin/memberView')
  },
  {
    path: "/adm/publishing_view/:pid",
    name: "publishing_view",
    component: () => import('@/pages/admin/publishingSave')
    //, beforeEnter: beforeAuth(true),
  },
  {   // 퍼블리싱 새로 등록
    path: "/adm/publishing_view",
    name: "publishing_new",
    component: () => import('@/pages/admin/publishingSave')
    //, beforeEnter: beforeAuth(true),
  },
  {
    path: "/adm/publishing_list",
    name: "publishing_list",
    component: () => import('@/pages/admin/publishingList')
  },

  // 플래닝 등록
  {
    path: "/adm/plan_list",
    name: "plan_list",
    component: () => import('@/pages/admin/planList')
  },
  {
    path: "/adm/plan_view/:idx",
    name: "plan_view",
    component: () => import('@/pages/admin/planSave')
  },
  {
    path: "/adm/plan_view",
    name: "plan_new",
    component: () => import('@/pages/admin/planSave')
  },

  // 이미지 구매 확인
  {
    path: "/adm/image_buy_list",
    name: "image_buy_list",
    component: () => import('@/pages/admin/buyImageList')
  },
  {
    path: "/adm/image_buy_view/:idx",
    name: "image_buy_view",
    component: () => import('@/pages/admin/buyImageView')
  },
  {
    path: "/adm/image_buy_view",
    name: "image_buy_new",
    component: () => import('@/pages/admin/buyImageView')
  },

  // 상품 등륵
  {
    path: "/adm/goods_list",
    name: "goods_list",
    component: () => import('@/pages/admin/goodsList')
  },
  {
    path: "/adm/goods_save/:it_id",
    name: "goods_save",
    component: () => import('@/pages/admin/goodsSave')
  },
  {
    path: "/adm/goods_save/",
    name: "goods_new",
    component: () => import('@/pages/admin/goodsSave')
  },
  {   // 주문 관리
    path: "/adm/order_list",
    name: "order_list",
    component: () => import('@/pages/admin/orderList')
  },
  {   // 주문 관리
    path: "/adm/order/:od_id",
    name: "order_detail",
    component: () => import('@/pages/admin/orderDetail')
  },

  /*{   // 주문 관리
        path: "/hooeni",
        name: "hooeni",
        component: () => import('@/pages/hooeni')
    },*/

  {   // 주문 관리
    path: "/pc_only",
    name: "pc_only",
    component: () => import('@/commonLayout/pcOnly.vue')
  },

  {   // 주문 관리
    path: "/adm/test1",
    name: "adm_test",
    component: () => import('@/pages/admin/memberViewAuthor.vue')
  },
  {   // 주문 관리
    path: "/adm/test1/:uid",
    name: "adm_test_view",
    component: () => import('@/pages/admin/memberViewAuthor.vue')
  },

  {
    path: "/.well-known/pki-validation/6FF48493980B3F0AF93F0A125189F3AA.txt",
    name: "ssl",
    component: () => import('@/pages/ssl.vue')
  },

  /* --------------------------------------------------------------------------------------------------------------------------------------------- */

  {
    path: "/adm/project/modify/:projectIdx",
    name: "project_modify",
    component: () => import('@/pages/admin/projectSave.vue')
  },
  {
    path: "/adm/project/write/",
    name: "admin_project_write",
    component: () => import('@/pages/admin/projectSave.vue')
  },
  {
    path: "/adm/project/list/",
    name: "project_list",
    component: () => import('@/pages/admin/projectList.vue')
  },
  {
    path: "/adm/project/view/:projectIdx",
    name: "project_view",
    component: () => import('@/pages/admin/projectView.vue')
  },

  {
    path: "/adm/project_after/list/",
    name: "admin_project_after_list",
    component: () => import('@/pages/admin/projectAfterList.vue')
  },
  {
    path: "/adm/project_after/write/",
    name: "none_project_after_write",
    component: () => import('@/pages/admin/projectAfterSave.vue')
  },

  // 프로젝트 idx 로 후기 수정
  {
    path: "/adm/project_after/write/project/:idx",
    name: "project_after_write",
    component: () => import('@/pages/admin/projectAfterSave.vue')
  },

  // 프로젝트 후기 idx 로 후기 수정
  {
    path: "/adm/project_after/write/after/:idx",
    name: "none_project_after_modify",
    component: () => import('@/pages/admin/projectAfterSave.vue')
  },

  {
    path: "/adm/project/user/",
    name: "user_project_list",
    component: () => import('@/pages/admin/userProjectList.vue')
  },


  {
    path: "/project/main",
    name: "project_main",
    component: () => import('@/pages/project.vue')
  },
  {
    path: "/project",
    name: "project_ing_list",
    component: () => import('@/pages/project.vue')
  },
  {
    path: "/project/complete",
    name: "project_after_list",
    component: () => import('@/pages/project.vue')
  },
  {
    path: "/project/complete/:afterIdx",
    name: "project_after_view",
    component: () => import('@/pages/projectView.vue')
  },


  {
    path: "/mypage/myinfo",
    name: "mypage_myinfo",
    component: () => import('@/pages/mypage/myInfo.vue')
  },



  {
    path: "/contact",
    name: "contact",
    component: () => import('@/pages/contact')
  },

  {
    path: "/adm/contact_list",
    name: "admin_contact_list",
    component: () => import('@/pages/admin/contact_list.vue')
  },

  {
    path: "/insight",
    name: "insight",
    component: () => import('@/pages/insight')
  },

  {
    path: "/shop",
    name: "shop",
    component: () => import('@/pages/shop')
  },

  // 프로젝트 등록
  {
    path: "/project/write",
    name: "project_write",
    component: () => import('@/pages/project/write.vue')
  },
  // 승인 전 관리자 확인 페이지
  {
    path: "/project/write/:idx",
    name: "project_write_idx",
    component: () => import('@/pages/project/write.vue')
  },
  // 프로젝트 승인 후 내용 작성 및 수정 페이지
  {
    path: "/project/ongoing/:idx",
    name: "project_ongoing_idx",
    component: () => import('@/pages/project/write.vue')
  },
  {
    path: "/project/edit",
    name: "project_edit",
    component: () => import('@/pages/project/edit.vue')
  },
  {
    path: "/project/edit/:projectIdx",
    name: "project_edit_idx",
    component: () => import('@/pages/project/edit.vue')
  },

  {
    path: "/mypage/wait_project",
    name: "my_wait_project",
    component: () => import('@/pages/mypage/waitProject.vue')
  },

  {
    path: "/404",
    name: "notFound",
    component: import('@/pages/NotFound'),
  },

  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },







  /* --------------------------------------------------------------------------------------------------------------------------------------------- */

];

// [라우터 설정 실시]
const router = createRouter({
  mode: 'history',
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
