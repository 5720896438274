import { createRouter, createWebHistory } from 'vue-router';
//import { createWebHistory, createRouter } from "vue-router";


// [라우터 path 접속 경로 설정]
const routes = [
  {
    path: "/",     // 경로
    name: "index",      // 이름
    //component: () => import('@/components/HelloWorld')
    component: () => import('@/pages/main')
  },
  {
    path: "/main",     // 경로
    name: "main",      // 이름
    component: () => import('@/components/MainComponent')
  },
  {
    path: "/join",     // 경로
    name: "join",      // 이름
    component: () => import('@/components/JoinComponent')
  },
  {
    path: "/login",     // 경로
    name: "login",      // 이름
    component: () => import('@/pages/login'),
    // beforeEnter: beforeAuth(false),
  },
  {   //플래닝
    path: "/planning",     // 경로
    name: "planning",      // 이름
    component: () => import('@/pages/planning'),
    // beforeEnter: beforeAuth(false),
  },
  {  //플래닝view
    path: "/planning_view",     // 경로
    name: "planning_view",      // 이름
    component: () => import('@/pages/planning_view'),
    // beforeEnter: beforeAuth(false),
  },
  {
    path: "/image/list",     // 경로
    name: "image_list",      // 이름
    component: () => import('@/commonLayout/pcOnly.vue'),
    // beforeEnter: beforeAuth(false),
  },
  {
    path: "/image/detail/:no",     // 경로
    name: "image_view",      // 이름
    // component: () => import('@/pages/image_view'),
    component: () => import('@/commonLayout/pcOnly.vue'),
    // beforeEnter: beforeAuth(false),
  },
  // 쇼핑 라우터
  {
    path: "/shop/list",     // 경로
    name: "shop_list",      // 이름
    // component: () => import('@/pages/shop_list'),
    component: () => import('@/commonLayout/pcOnly.vue'),
    // beforeEnter: beforeAuth(false),
  },
  {
    path: "/shop/:it_id",     // 경로
    name: "shop_view",      // 이름
    // component: () => import('@/pages/shop_view'),
    component: () => import('@/commonLayout/pcOnly.vue'),
    // beforeEnter: beforeAuth(false),
  },
  {   // 기획방
    path: "/room/list",     // 경로
    name: "room_list",      // 이름
    component: () => import('@/pages/room_list'),
    // beforeEnter: beforeAuth(false),
  },
  {   // 기획방 코멘트 모달
    path: "/room/comment_w",     // 경로
    name: "room_comment_w",      // 이름
    component: () => import('@/pages/comment_w'),
    // beforeEnter: beforeAuth(false),
  },
  {   // 기획방뷰
    path: "/room/view/:idx",     // 경로
    name: "room_view",      // 이름
    component: () => import('@/pages/room_view'),
    // beforeEnter: beforeAuth(false),
  },
  {   // 기획방등록
    path: "/room/write",     // 경로
    name: "room_write",      // 이름
    component: () => import('@/pages/room_write'),
    // beforeEnter: beforeAuth(false),
  },
  {   // 기획방 수정
    path: "/room/write/:idx",     // 경로
    name: "room_edit",      // 이름
    component: () => import('@/pages/room_write'),
    // beforeEnter: beforeAuth(false),
  },
  {   // 기획방 참여방법
    path: "/room/info",     // 경로
    name: "room_info",      // 이름
    component: () => import('@/pages/room_info'),
    // beforeEnter: beforeAuth(false),
  },
  {   // 기획방리뷰
    path: "/room/review",     // 경로
    name: "room_review",      // 이름
    component: () => import('@/pages/room_review'),
    // beforeEnter: beforeAuth(false),
  },
  {   // 기획방 세시에
    path: "/room/3pm",     // 경로
    name: "room_3pm",      // 이름
    component: () => import('@/pages/room_3pm'),
    // beforeEnter: beforeAuth(false),
  },
  {   // 장바구니
    path: "/cart",     // 경로
    name: "cart",      // 이름
    component: () => import('@/pages/cart'),
    // beforeEnter: beforeAuth(false),
  },
  {   // 주문서 작성
    path: "/order",     // 경로
    name: "order",      // 이름
    component: () => import('@/pages/order'),
    // beforeEnter: beforeAuth(false),
  },
  {   // 주문완료
    path: "/order_complete/:od_id",     // 경로
    name: "order_complete",      // 이름
    component: () => import('@/pages/order_complete'),
    // beforeEnter: beforeAuth(false),
  },
  {   // 주문완료
    path: "/order/detail/:od_id",     // 경로
    name: "order_detail",      // 이름
    component: () => import('@/pages/order_detail'),
    // beforeEnter: beforeAuth(false),
  },
  {   // 마이페이지-주문내역
    path: "/mypage/order_list",     // 경로
    name: "my_order_list",      // 이름
    component: () => import('@/pages/my_order_list'),
    // beforeEnter: beforeAuth(false),
  },
  {   // 마이페이지-주문내역상세
    path: "/mypage/order_view",     // 경로
    name: "my_order_view",      // 이름
    component: () => import('@/pages/my_order_view'),
    // beforeEnter: beforeAuth(false),
  },



  // 관리자 -------------------------------------------------------------------------------
  {
    path: "/adm/member_list",     // 경로
    name: "member_list",      // 이름
    component: () => import('@/pages/admin/memberList')
    //, beforeEnter: beforeAuth(true),
  },
  {
    path: "/adm/member_list/author",     // 경로
    name: "member_author",      // 이름
    component: () => import('@/pages/admin/memberListAuthor')
    //, beforeEnter: beforeAuth(true),
  },
  {
    path: "/adm/member_list/author_wait",     // 경로
    name: "member_author_wait",      // 이름
    component: () => import('@/pages/admin/memberListAuthor')
    //, beforeEnter: beforeAuth(true),
  },
  {
    path: "/adm/author_view/:uid",     // 경로
    name: "author_view",      // 이름
    component: () => import('@/pages/admin/memberViewAuthor')
  }, {
    path: "/adm/author_new/",     // 경로
    name: "author_new",      // 이름
    component: () => import('@/pages/admin/memberViewAuthor')
  }, {
    path: "/adm/member_list/normal",     // 경로
    name: "member_normal",      // 이름
    component: () => import('@/pages/admin/memberList')
    //, beforeEnter: beforeAuth(true),
  }, {
    path: "/adm/member_list/design",     // 경로
    name: "member_design",      // 이름
    component: () => import('@/pages/admin/memberList')
    //, beforeEnter: beforeAuth(true),
  }, {
    path: "/adm/member_list/plan",     // 경로
    name: "member_plan",      // 이름
    component: () => import('@/pages/admin/memberList')
    //, beforeEnter: beforeAuth(true),
  },
  {
    path: "/adm/member_view/:uid",     // 경로
    name: "member_view",      // 이름
    component: () => import('@/pages/admin/memberView')
  },
  {
    path: "/adm/member_view",     // 경로
    name: "member_new",      // 이름
    component: () => import('@/pages/admin/memberView')
  },
  {
    path: "/adm/publishing_view/:pid",     // 경로
    name: "publishing_view",      // 이름
    component: () => import('@/pages/admin/publishingSave')
    //, beforeEnter: beforeAuth(true),
  },
  {   // 퍼블리싱 새로 등록
    path: "/adm/publishing_view",     // 경로
    name: "publishing_new",      // 이름
    component: () => import('@/pages/admin/publishingSave')
    //, beforeEnter: beforeAuth(true),
  },
  {
    path: "/adm/publishing_list",     // 경로
    name: "publishing_list",      // 이름
    component: () => import('@/pages/admin/publishingList')
  },

  // 플래닝 등록
  {
    path: "/adm/plan_list",     // 경로
    name: "plan_list",      // 이름
    component: () => import('@/pages/admin/planList')
  },
  {
    path: "/adm/plan_view/:idx",     // 경로
    name: "plan_view",      // 이름
    component: () => import('@/pages/admin/planSave')
  },
  {
    path: "/adm/plan_view",     // 경로
    name: "plan_new",      // 이름
    component: () => import('@/pages/admin/planSave')
  },

  // 이미지 구매 확인
  {
    path: "/adm/image_buy_list",     // 경로
    name: "image_buy_list",      // 이름
    component: () => import('@/pages/admin/buyImageList')
  },
  {
    path: "/adm/image_buy_view/:idx",     // 경로
    name: "image_buy_view",      // 이름
    component: () => import('@/pages/admin/buyImageView')
  },
  {
    path: "/adm/image_buy_view",     // 경로
    name: "image_buy_new",      // 이름
    component: () => import('@/pages/admin/buyImageView')
  },

  // 상품 등륵
  {
    path: "/adm/goods_list",     // 경로
    name: "goods_list",      // 이름
    component: () => import('@/pages/admin/goodsList')
  },
  {
    path: "/adm/goods_save/:it_id",     // 경로
    name: "goods_save",      // 이름
    component: () => import('@/pages/admin/goodsSave')
  },
  {
    path: "/adm/goods_save/",     // 경로
    name: "goods_new",      // 이름
    component: () => import('@/pages/admin/goodsSave')
  },
  {   // 주문 관리
    path: "/adm/order_list",     // 경로
    name: "order_list",      // 이름
    component: () => import('@/pages/admin/orderList')
  },
  {   // 주문 관리
    path: "/adm/order/:od_id",     // 경로
    name: "order_detail",      // 이름
    component: () => import('@/pages/admin/orderDetail')
  },

  /*{   // 주문 관리
        path: "/hooeni",     // 경로
        name: "hooeni",      // 이름
        component: () => import('@/pages/hooeni')
    },*/

  {   // 주문 관리
    path: "/pc_only",     // 경로
    name: "pc_only",      // 이름
    component: () => import('@/commonLayout/pcOnly.vue')
  },

];

// [라우터 설정 실시]
const router = createRouter({
  mode: 'history',
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
