// 모든 컴포넌트가 공유 할 수 있는 글로별 영역

export default {
  install(Vue){
    Vue.config.globalProperties.$getSum = function (one, two){
      return one + two;
    };

    // 로컬 스토리지 저장
    Vue.config.globalProperties.$setLocalStoage = function (key, value) {
      localStorage.setItem(key, value);
    };

    // 로컬 스토리지 저장 값 호춣
    Vue.config.globalProperties.$getLocalStoage = function (key) {
      return localStorage.getItem(key);
    };

    // 로컬 스토리 특정 값 삭제
    Vue.config.globalProperties.$delItemLocalStoage = function (key) {
      localStorage.removeItem(key);
    };

    // 로컬 스토리지 전체 데이터 삭제
    Vue.config.globalProperties.$delAllLocalStoage = function () {
      localStorage.clear();
    };
  }
};