import { createStore } from "vuex";

// store 데이터 설정
const store = createStore({
  state: {
    name: "test"
  },
  // state 변수들을 get 호출
  getters: {
    getUserInfo(state){
      return "이름 : " + state.name;
    }
  },
  // 변수들을 조작하는 함수들
  mutations:{
    setUserInfo( state, name ){
      state.name = name;
    }
  },
  // 비동기 처리 함수들
  actions: {

  }
});

export default store;