import { createApp } from 'vue';
import App from './App.vue';
import router from '@/router/index.js';
import mobile_router from '@/router/mobile_index.js';
import axios from "axios";

import global from "@/global/global";
import store from "@/store/store";
import VueCryptojs from "vue-cryptojs/src";
import MobileDetect from "mobile-detect";

import '@/assets/css/reset.css';
import '@/assets/css/3pm.css';
import '@/styles/app.css';
// import '@/assets/css/tailwind.css'

axios.defaults.paramsSerializer = function (paramObj) {
  const params = new URLSearchParams();
  for (const key in paramObj) {
    params.append(key, paramObj[key]);
  }

  return params.toString();
};
axios.defaults.timeout = 100000;
// axios.defaults.withCredentials = true;
axios.defaults.baseURL = "http://" + window.location.host;
axios.defaults.port = ":" + window.location.port;
// 캐싱 방지
axios.defaults.headers.get['Cache-Control'] = 'no-cache';
axios.defaults.headers.get['Pragma'] = 'no-cache';

axios.defaults.headers.common['Content-Type'] = 'application/json;charset=utf-8';
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
axios.defaults.headers.common['Access-Control-Allow-Headers'] = '*';
axios.defaults.headers.common['Access-Control-Allow-Methods'] = "GET, POST, PUT, DELETE, OPTIONS";
// axios.defaults.headers.common['Access-Control-Allow-Credentials'] = true;

axios.interceptors.request.use((request) => {
  if(request.port !== ":8080"){
    if(request.url.indexOf("/api") > -1){
      // 3pm back
      request.baseURL = "https://3pm.kr";
      request.url = request.url.replace("/api/", "/");
    }
    else if(request.url.indexOf("/author") > -1){
      // 3pm 작가 사이트
      // request.baseURL = "https://pm3kr.cafe24.com/with/api";
      request.baseURL = "http://with.3pm.kr/api";
      request.url = request.url.replace("/author", "");
    }
  }
  else if(request.port === ":8080"){
    if(request.url.indexOf("/api") > -1) {
      request.baseURL = "http://api.3pm.kr/";
      request.url = request.url.replace("/api/", "/");
    }
    else if(request.url.indexOf("/author") > -1){
      // 3pm 작가 사이트
      // request.baseURL = "https://pm3kr.cafe24.com/with/api";
      request.baseURL = "http://with.3pm.kr/api";
      request.url = request.url.replace("/author", "");
    }

  }
  request.headers.authorization = localStorage.getItem('authorization');      // 토큰

  return request;
});

const md = new MobileDetect(window.navigator.userAgent);

const app = createApp(App);
app.config.globalProperties.axios = axios;
app.config.globalProperties.$store = store; // [store / 저장소]
app.config.globalProperties.$CryptoJS = VueCryptojs;

let view_mode = localStorage.getItem("view_mode");
// view_mode = (view_mode === "PC" || view_mode === null) ? "PC" : "MOBILE";

if( md.mobile() && view_mode !== "PC" ){
  app.use(mobile_router);
}
else {
  app.use(router);
}
app.use(global).use(store).use(VueCryptojs).mount('#app');

window.app = app;